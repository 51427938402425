import React from "react";

const Projects = () => {
	return (
		<div className="">
			<h2>Page under construction.</h2>
		</div>
	)
}

export default Projects;
